import React from "react"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"
import { Formik, Field } from "formik"
import * as Yup from "yup"

import Layout from "../components/layout"

import icoMapMaker from "../assets/old/img/map-marker.png"

export default class extends React.Component {
  componentDidMount() {
    const $ = window.$
    const onLoadGmap3 = callback => {
      if (!!$.gmap3) {
        callback()
      } else {
        setTimeout(() => {
          onLoadGmap3(callback)
        }, 500)
      }
    }
    setTimeout(() => {
      onLoadGmap3(() => {
        $.gmap3(false)
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyCShbl6qVZFD0tn4gmxBmOlKrntUnJDQZo&callback=initMap"
        $("head").append(script)
      })
    }, 500)
    window.initMap = () => {
      $.gmap3(false)
      $(this.gmap)
        .gmap3({
          address: "Web del Seguro, Alvear, Ramos Mejía, Provincia de Buenos Aires",
          zoom: 18,
          disableDefaultUI: false,
          scrollwheel: false,
          styles: [
            {
              featureType: "landscape",
              stylers: [
                { hue: "#646473" },
                { saturation: 43.400000000000006 },
                { lightness: 37.599999999999994 },
                { gamma: 1 },
              ],
            },
            {
              featureType: "road.highway",
              stylers: [
                { hue: "#646473" },
                { saturation: -61.8 },
                { lightness: 45.599999999999994 },
                { gamma: 1 },
              ],
            },
            {
              featureType: "road.arterial",
              stylers: [
                { hue: "#646473" },
                { saturation: -100 },
                { lightness: 51.19999999999999 },
                { gamma: 1 },
              ],
            },
            {
              featureType: "road.local",
              stylers: [{ hue: "#646473" }, { saturation: -100 }, { lightness: 52 }, { gamma: 1 }],
            },
            {
              featureType: "water",
              stylers: [
                { hue: "#313140" },
                { saturation: -13.200000000000003 },
                { lightness: 2.4000000000000057 },
                { gamma: 1 },
              ],
            },
            {
              featureType: "poi",
              stylers: [
                { hue: "#535362" },
                { saturation: -99.0989010989011234 },
                { lightness: 11.200000000000017 },
                { gamma: 1 },
              ],
            },
          ],
        })
        .marker({
          address: "Web del Seguro, Alvear, Ramos Mejía, Provincia de Buenos Aires",
          icon: icoMapMaker,
        })
        .infowindow({
          content: "Aquí estamos!!",
        })
        .then(function(infowindow) {
          var map = this.get(0)
          var marker = this.get(1)
          marker.addListener("mouseover", function() {
            infowindow.open(map, marker)
          })
          marker.addListener("mouseout", function() {
            infowindow.close(map, marker)
          })
        })
    }
  }

  render = () => (
    <Layout menuActivo="contacto">
      <Helmet>
        <title>Contacto directo</title>
        <meta
          name="description"
          content="Para consultas, siniestros o sugerencias acercate a nuestra oficina. Completá el formulario. Contactanos online o llamanos al (011) 3037-0204."
        />
        <script src={withPrefix("/js/gmap3.min.js")} />
      </Helmet>

      <section className="container-fluid mb-5 d-none d-md-block">
        <div className="row">
          <div className="col-md-3 col-sm-6 border-right py-2 border-bottom">
            <a className="scroll-to icon-box text-center mx-auto box-shadow-none px-0" href="#map">
              <div className="icon-box-icon">
                <i className="fe-icon-map-pin" />
              </div>
              <h3 className="icon-box-title">Ubicación</h3>
              <p className="icon-box-text font-weight-medium">Alvear 1563, Ramos Mejía</p>
            </a>
          </div>
          <div className="col-md-3 col-sm-6 py-2 border-right border-bottom">
            <a className="icon-box text-center mx-auto box-shadow-none px-0" href="tel:01130370204">
              <div className="icon-box-icon">
                <i className="fe-icon-phone" />
              </div>
              <h3 className="icon-box-title">Teléfono</h3>
              <p className="icon-box-text font-weight-medium">011 3037-0204</p>
            </a>
          </div>
          <div className="col-md-3 col-sm-6 py-2 border-right border-bottom">
            <a
              className="icon-box text-center mx-auto box-shadow-none px-0"
              href="mailto:info@webdelseguro.com.ar"
            >
              <div className="icon-box-icon">
                <i className="fe-icon-mail" />
              </div>
              <h3 className="icon-box-title">Email</h3>
              <p className="icon-box-text font-weight-medium">info@webdelseguro.com.ar</p>
            </a>
          </div>
          <div className="col-md-3 col-sm-6 py-2 border-bottom">
            <a
              className="icon-box text-center mx-auto box-shadow-none px-0"
              href="https://www.facebook.com/webdelseguro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon-box-icon">
                <i className="fe-icon-facebook" />
              </div>
              <h3 className="icon-box-title">Seguinos</h3>
              <p className="icon-box-text font-weight-medium">Facebook, Twitter, LinkedIn</p>
            </a>
          </div>
        </div>
      </section>

      <section className="container mb-5 pb-3">
        <div className="wizard">
          <div className="wizard-body pt-3">
            <h2 className="h4 text-center">Contacto directo</h2>
            <p className="text-muted text-center">
              Por favor ingrese sus datos y un representante del centro de atención al cliente se
              comunicará con usted.
            </p>
            <Formik
              initialStatus={{
                enviado: false,
              }}
              initialValues={{
                nombre: "",
                email: "",
                telefono: "",
                asunto: "",
                mensaje: "",
              }}
              validationSchema={() =>
                Yup.object().shape({
                  nombre: Yup.string().required("Requerido"),
                  email: Yup.string()
                    .email("Inválido")
                    .required("Requerido"),
                  telefono: Yup.string()
                    .trim()
                    .matches(/^0\d{2,4}\s?15\d{6,8}$/, "Ingrese el 0 y el 15")
                    .required("Requerido"),
                  mensaje: Yup.string().required("Requerido"),
                })
              }
              onSubmit={(values, { setSubmitting, setStatus }) => {
                const asunto = `Formulario de contacto: ${values.nombre}`
                const cuerpo = `
                <p>Has recibido un mensaje desde el formulario de contacto de tu sitio web.</p>
                <h2>Datos del contacto</h2>
                <dl>
                  <dt>Nombre</dt>
                  <dd>${values.nombre}</dd>
                  <dt>Teléfono</dt>
                  <dd>${values.telefono}</dd>
                  <dt>Email</dt>
                  <dd>${values.email}</dd>
                  <dt>Asunto</dt>
                  <dd>${values.asunto ? values.asunto : "-"}</dd>
                  <dt>Mensaje</dt>
                  <dd style="white-space: pre-wrap">${values.mensaje}</dd>
                </dl>
              `
                const params = { asunto, cuerpo, responder: values.email }
                fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
                  method: "POST",
                  body: JSON.stringify(params),
                  headers: {
                    "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
                    "Content-Type": "application/json",
                  },
                }).then(response => {
                  setStatus({ enviado: true })
                  setSubmitting(false)
                })
              }}
            >
              {({ status, handleSubmit, isSubmitting, isValid }) => (
                <Form onSubmit={handleSubmit}>
                  {status.enviado && (
                    <Alert variant="success">
                      ¡Enviado! Nos pondremos en contacto a la brevedad, muchas gracias.
                    </Alert>
                  )}
                  <div className="row pt-3">
                    <div className="col-sm-6">
                      <Form.Group controlId="nombre">
                        <Form.Label>
                          Nombre <span className="text-danger font-weight-medium">*</span>
                        </Form.Label>
                        <Field name="nombre">
                          {({ form, field }) => (
                            <>
                              <Form.Control
                                {...field}
                                isInvalid={form.touched[field.name] && form.errors[field.name]}
                              />
                              {form.touched[field.name] && form.errors[field.name] && (
                                <Form.Control.Feedback type="invalid">
                                  {form.errors[field.name]}
                                </Form.Control.Feedback>
                              )}
                            </>
                          )}
                        </Field>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group controlId="email">
                        <Form.Label>
                          Email <span className="text-danger font-weight-medium">*</span>
                        </Form.Label>
                        <Field name="email">
                          {({ form, field }) => (
                            <>
                              <Form.Control
                                {...field}
                                type="email"
                                isInvalid={form.touched[field.name] && form.errors[field.name]}
                              />
                              {form.touched[field.name] && form.errors[field.name] && (
                                <Form.Control.Feedback type="invalid">
                                  {form.errors[field.name]}
                                </Form.Control.Feedback>
                              )}
                            </>
                          )}
                        </Field>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Group controlId="telefono">
                        <Form.Label>
                          Teléfono <span className="text-danger font-weight-medium">*</span>
                        </Form.Label>
                        <Field name="telefono">
                          {({ form, field }) => (
                            <>
                              <Form.Control
                                {...field}
                                placeholder="Ej: 011 1577778888"
                                isInvalid={form.touched[field.name] && form.errors[field.name]}
                              />
                              {form.touched[field.name] && form.errors[field.name] && (
                                <Form.Control.Feedback type="invalid">
                                  {form.errors[field.name]}
                                </Form.Control.Feedback>
                              )}
                            </>
                          )}
                        </Field>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group controlId="asunto">
                        <Form.Label>Asunto</Form.Label>
                        <Field name="asunto">
                          {({ form, field }) => (
                            <>
                              <Form.Control
                                {...field}
                                placeholder="Consulta, reclamo, siniestro"
                                isInvalid={form.touched[field.name] && form.errors[field.name]}
                              />
                              {form.touched[field.name] && form.errors[field.name] && (
                                <Form.Control.Feedback type="invalid">
                                  {form.errors[field.name]}
                                </Form.Control.Feedback>
                              )}
                            </>
                          )}
                        </Field>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="form-group">
                    <Form.Group controlId="mensaje">
                      <Form.Label>
                        Mensaje <span className="text-danger font-weight-medium">*</span>
                      </Form.Label>
                      <Field name="mensaje">
                        {({ form, field }) => (
                          <>
                            <Form.Control
                              {...field}
                              as="textarea"
                              rows="4"
                              isInvalid={form.touched[field.name] && form.errors[field.name]}
                            />
                            {form.touched[field.name] && form.errors[field.name] && (
                              <Form.Control.Feedback type="invalid">
                                {form.errors[field.name]}
                              </Form.Control.Feedback>
                            )}
                          </>
                        )}
                      </Field>
                    </Form.Group>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || status.enviado}
                    >
                      {isSubmitting ? "UN MOMENTO..." : status.enviado ? "ENVIADO" : "ENVIAR"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>

      <section
        id="map"
        style={{ height: 400 }}
        ref={e => {
          this.gmap = e
        }}
      />
    </Layout>
  )
}
